<template>
  <v-container
    fluid
    tag="section"
  >
    <base-material-tabs
      v-model="mainTab"
      background-color="transparent"
      centered
      icons-and-text
      show-arrows
    >
      <v-tab
        v-for="(tab, i) in tabs"
        :key="`tab-${i}`"
        :to="tab.to"
      >
        {{ tab.text }}
        <v-icon v-text="tab.icon" />
      </v-tab>
    </base-material-tabs>

    <v-progress-linear
      v-if="loading"
      indeterminate
    />
    <router-view
      v-else-if="shouldHideProfile"
      :customer="customer"
    />
    <v-row v-else>
      <v-col
        cols="12"
        md="8"
      >
        <router-view :customer="customer" />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <profile :customer="customer" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import { USER } from '@/constants'

  export default {
    components: {
      Profile: () => import('./Profile'),
    },

    data: () => ({
      mainTab: 0,
      mainTabs: [
        { text: 'General', icon: 'mdi-information', to: 'general' },
        { text: 'Address', icon: 'mdi-map-marker', to: 'address' },
        { text: 'Contacts', icon: 'mdi-card-account-phone', to: 'contacts' },
        { text: 'Files', icon: 'mdi-file', to: 'files' },
      ],
      loading: false,
    }),

    computed: {
      ...mapState({
        customer: state => state.items.customer,
      }),

      tabs () {
        const notes = { text: 'Notes', icon: 'mdi-pen', to: 'notes' }
        const dashboard = { text: 'Dashboard', icon: 'mdi-view-dashboard', to: 'dashboard' }
        if (this.$route.fullPath.includes('clients')) {
          return [
            dashboard,
            ...this.mainTabs,
            { text: 'Policies', icon: 'mdi-numeric', to: 'policies' },
            notes,
          ]
        }
        return [
          dashboard,
          ...this.mainTabs,
          notes,
        ]
      },

      shouldHideProfile () {
        const path = this.$route.path
        return path.endsWith('files')
      },
    },

    mounted () {
      this.getCustomer()
    },

    methods: {
      ...mapActions({
        getItem: 'getItem',
      }),

      async getCustomer () {
        this.loading = true
        const userOrCompany = this.$route.params.id.split('-')[0]
        const id = this.$route.params.id.split('-')[1]
        const url = userOrCompany === USER ? `individual/individual/${id}?agent=0` : `company/company/${id}?agency=0`

        const item = { url, item: 'customer' }
        await this.getItem(item)
        this.loading = false
      },
    },
  }
</script>
